import { APIFilter } from '@/utils/api'

export default {
  async selectDocumento (Vue, filter, search, sorter, page, id, idtabla) {
    const apiFilter = new APIFilter()
      .addGT('estado', 0)
      .addExact('idtabla', idtabla)
    if (id) {
      apiFilter.addExact('id', id)
    }
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.documento.searchFilter(search))
    }
    const resp = await Vue.$api.call(
      'documento.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectDocumentoRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('iddocumento', pks)
    const resp = await Vue.$api.call('documento.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
