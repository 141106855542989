<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title>
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <b10-list-item-right-avatar
      :color="formattedItem.badgeColor"
    >
      {{ formattedItem.badge }}
    </b10-list-item-right-avatar>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    hasPermBorrar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$online.documento.title(item)
      item.subtitle = this.$online.documento.subtitle(item)
      const badge = this.$online.documento.badge(item)
      item.badge = badge.badge
      item.badgeColor = badge.badgeColor
      return item
    }
  },
}
</script>
